/* dm-sans-regular - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-italic - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-500 - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-500italic - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-700 - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* dm-sans-700italic - latin */
@font-face {
    font-display: block; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/DM-Sans/dm-sans-v11-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
